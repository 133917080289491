<template>
    <Page title="ACP Groups" icon="mdi-account">
        <div class="constrain-width">
            <smart-table
                v-if="tableItems.length"
                :headers="tableHeaders"
                :items="tableItems"
                :items-per-page="20"
                class="elevation-1"
                @click:row="clickRow"
                dense
                isFilter
            />
        </div>
        <template v-slot:header>
            <popup
                trigger-button-label="Add"
                trigger-button-color="primary"
                dialog-title="Add ACP Group"
                dialog-action-button-label="Save"
                :dialog-action-func="saveNewAcpGroup"
                :form-schema="formSchema"
                v-model="formModel"
            />
        </template>
    </Page>
</template>

<script>
import Popup from '@/components/form/Popup';

import api from '@/api';

const formSchema = {
    acpName: {
        component: 'text-field',
        label: 'ACP Group Name',
        cols: 6,
        xxxautofocus: true,
    },
};

export default {
    name: 'GroupList',
    components: { Popup },
    data() {
        return {
            tableHeaders: [],
            tableItems: [],
            tableData: null,
            isDataLoaded: false,
            formSchema,
            formModel: {},
        };
    },
    methods: {
        newAcpGroup() {
            this.$router.push(`acpgroup/new`);
        },
        async getTableData() {
            // console.log('!');
            const result = await api.get(this.$store, 'acpgroup');
            // console.log(result.data);
            this.tableData = [...result.data];
            this.setTableData();
            this.isDataLoaded = true;
        },
        clickRow(row) {
            this.$router.push(`acpgroup/${row.id}`);
        },
        setTableData() {
            this.tableHeaders = [
                {
                    text: 'Name',
                    align: 'left',
                    sortable: true,
                    value: 'acpName',
                },
            ];

            this.tableItems = this.tableData.map((oneTableData) => ({
                id: oneTableData.id,
                // email: oneTableData.email,
                acpName: oneTableData.acpName,
                searchFields: oneTableData.searchFields,
            }));
            // set the headers and column
        },
        async saveNewAcpGroup() {
            console.log('action button for the form has been pressed.', this.formModel.acpName);
            const result = await api.post(this.$store, 'acpgroup', {
                acpName: this.formModel.acpName,
            });

            if (result && result.data) {
                const acpGroup = result.data;

                if (acpGroup && acpGroup.id) {
                    this.$router.push(`/auth/admin/acpgroup/${acpGroup.id}`);
                }
            }
        },
    },
    async mounted() {
        await this.getTableData();
    },
};
</script>

<style scoped></style>
